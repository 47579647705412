$input: $grey-darker !default
$input-background: $white !default
$input-border: $grey-lighter !default

$input-hover: $grey-darker !default
$input-hover-border: $grey-light !default

$input-focus: $grey-darker !default
$input-focus-border: $link !default

$input-disabled: $text-light !default
$input-disabled-background: $background !default
$input-disabled-border: $background !default

$input-arrow: $link !default

$input-icon: $grey-lighter !default
$input-icon-active: $grey !default

$input-radius: $radius !default

=input
  +control
  background-color: $input-background
  border: 1px solid $input-border
  color: $input

  &:hover,
  &.is-hovered
    border-color: $input-hover-border

  &:focus,
  &.is-focused,
  &:active,
  &.is-active
    border-color: $input-focus-border

  &[disabled],
  &.is-disabled
    background-color: $input-disabled-background
    border-color: $input-disabled-border
    box-shadow: none
    color: $input-disabled

    &:placeholder
      color: rgba($input, .3)

.input,
.textarea
  +input
  box-shadow: inset 0 1px 2px rgba($black, .1)
  max-width: 100%
  width: 100%
  transition: border-color $speed $easing
  will-change: border-color

  // Modifiers
  &.is-fullwidth
    display: block
    width: 100%

  &.is-inline
    display: inline
    width: auto

.textarea
  display: block
  line-height: 1.25
  max-height: 600px
  max-width: 100%
  min-height: 120px
  min-width: 100%
  padding: 10px
  resize: vertical

.checkbox,
.radio
  align-items: center
  cursor: pointer
  display: inline-flex
  flex-wrap: wrap
  justify-content: flex-start
  position: relative
  vertical-align: middle

  input
    appearance: none
    display: none

    & + label
      cursor: pointer
      line-height: 22px
      margin-left: 2em
      position: relative

      &:before
        background-color: $white
        border: 1px solid darken($input-border, 10%)
        box-shadow: inset 0 1px 2px rgba($black, .1)
        color: $white
        content: ''
        height: 20px
        left: -2em
        position: absolute
        transition: background $speed $easing, border-color $speed $easing
        width: 20px

    &:focus + label:before
      border-color: $input-focus-border
      outline: none

    &:hover + label:before
      border-color: $input-hover-border

    &:checked + label:before
      background-color: $lv-green
      content: '\f00c'
      font-family: 'FontAwesome'
      line-height: 18px !important
      padding: 0 1px

.radio
  input
    & + label
      &:before
        border-radius: 100%

    &:checked + label:before
      content: '\f111'

  & + .radio
    margin-left: .5em

.select
  display: inline-block
  height: 2.5em
  position: relative
  vertical-align: top

  &:after
    +arrow($input-arrow)
    margin-top: -.375em
    right: 1.125em
    top: 50%
    z-index: 4

  select
    +input
    cursor: pointer
    display: block
    font-size: 1em
    outline: none
    padding-right: 2.5em
    transition: border-color $speed $easing

    &:hover
      border-color: $input-hover-border

    &::ms-expand
      display: none

  // States
  &:hover
    &:after
      border-color: $input-hover

  // Modifiers
  &.is-fullwidth
    width: 100%

    select
      width: 100%

.label
  color: $input
  display: block
  font-weight: bold

  &:not(:last-child)
    margin-bottom: .5em

// Containers
.control-label
  +mobile
    margin-bottom: .5em
  +tablet
    flex-basis: 0
    flex-grow: 1
    flex-shrink: 0
    margin-right: 1.5em
    padding-top: .5em
    text-align: right

.control
  position: relative
  text-align: left

  &:not(:last-child)
    margin-bottom: .75rem

  &.has-icon
    .icon
      color: $input-icon
      left: ($size-normal * 2.5) * 0.5
      pointer-events: none
      position: absolute
      top: ($size-normal * 2.5) * 0.5
      transform: translateX(-50%) translateY(-50%)
      z-index: 4

    .input
      padding-left: 2.5em

      &:focus
        & ~ .icon
          color: $input-icon-active

.field
  &:not(:last-child)
    margin-bottom: .75rem

  // Modifiers
  &.has-addons
    display: flex
    justify-content: flex-start

    .control
      margin-right: -1px

      &:first-child
        .button,
        .input,
        .select select
          border-bottom-left-radius: $input-radius
          border-top-left-radius: $input-radius

      &:last-child
        .button,
        .input,
        .select select
          border-bottom-right-radius: $input-radius
          border-top-right-radius: $input-radius

      .button,
      .input,
      .select select
        border-radius: 0

        &:hover,
        &.is-hovered
          z-index: 2

        &:focus,
        &.is-focused,
        &:active,
        &.is-active
          z-index: 3

          &:hover
            z-index: 4

      &.is-expanded
        flex-grow: 1

    &.has-addons-centered
      justify-content: center

    &.has-addons-right
      justify-content: flex-end

    &.has-addons-fullwidth
      .control
        flex-grow: 1
        flex-shrink: 0

  &.is-grouped
    display: flex
    justify-content: flex-start

    & > .control
      flex-shrink: 0

      &:not(:last-child)
        margin-bottom: 0
        margin-right: 0.75rem

      &.is-expanded
        flex-grow: 1
        flex-shrink: 1

    &.is-grouped-centered
      justify-content: center

    &.is-grouped-right
      justify-content: flex-end

  &.is-horizontal
    +tablet
      display: flex
