$button: $grey-darker !default
$button-background: $white !default
$button-border: $grey-lighter !default

$button-hover: $link-hover !default
$button-hover-border: $link-hover-border !default

$button-focus: $link-focus !default
$button-focus-border: $link-focus-border !default

$button-active: $link-active !default
$button-active-border: $link-active-border !default

$button-shadow-inset: inset 0 1px 2px rgba($black, .2) !default

@function buttonIconSpacing($button-font-size, $icon-width)
  $button-value: removeUnit($button-font-size)
  $button-height: 2.5rem * $button-value
  $button-horizontal-padding: 2 * 0.75rem * $button-value
  $difference: $button-height - $button-horizontal-padding - $icon-width

  @return $difference * 0.5

=button-icon($button-font-size)
  $small-offset: buttonIconSpacing($button-font-size, 1rem)
  $normal-offset: buttonIconSpacing($button-font-size, 1.5rem)
  $medium-offset: buttonIconSpacing($button-font-size, 2rem)
  $large-offset: buttonIconSpacing($button-font-size, 3rem)

  .icon
    &:first-child:not(:last-child)
      margin-left: $normal-offset
      margin-right: $button-font-size * 0.5

    &:last-child:not(:first-child)
      margin-left: $button-font-size * 0.5
      margin-right: $normal-offset

    &:first-child:last-child
      // The -1px is to account for the button 1px border
      margin-left: calc(-1px + #{$normal-offset})
      margin-right: calc(-1px + #{$normal-offset})

    &.is-small
      &:first-child:not(:last-child)
        margin-left: $small-offset

      &:last-child:not(:first-child)
        margin-right: $small-offset

      &:first-child:last-child
        margin-left: calc(-1px + #{$small-offset})
        margin-right: calc(-1px + #{$small-offset})

    &.is-medium
      &:first-child:not(:last-child)
        margin-left: $medium-offset

      &:last-child:not(:first-child)
        margin-right: $medium-offset

      &:first-child:last-child
        margin-left: calc(-1px + #{$medium-offset})
        margin-right: calc(-1px + #{$medium-offset})

    &.is-large
      &:first-child:not(:last-child)
        margin-left: $large-offset

      &:last-child:not(:first-child)
        margin-right: $large-offset

      &:first-child:last-child
        margin-left: calc(-1px + #{$large-offset})
        margin-right: calc(-1px + #{$large-offset})

=button-small
  +button-icon($size-small)
  border-radius: $radius-small
  font-size: $size-small

=button-medium
  +button-icon($size-medium)
  font-size: $size-medium

=button-large
  +button-icon($size-large)
  font-size: $size-large

.button
  +control
  background-color: $button-background
  border: 1px solid $button-border
  color: $button
  cursor: pointer
  font-weight: $weight-bold
  justify-content: center
  padding-left: .75em
  padding-right: .75em
  text-align: center
  transition: border-color $speed $easing, background-color $speed $easing
  user-select: none
  white-space: nowrap
  will-change: border-color, background-color

  strong
    color: inherit

  +button-icon($size-normal)

  // States
  &:hover,
  &.is-hovered
    border-color: $button-hover-border
    color: $button-hover

  &:focus,
  &.is-focused
    border-color: $button-focus-border
    box-shadow: 0 0 .5em rgba($button-focus-border, .25)
    color: $button-focus

  &:active,
  &.is-active
    border-color: $button-active-border
    box-shadow: $button-shadow-inset
    color: $button-active

  // Sizes
  &.is-small
    +button-small

  &.is-medium
    +button-medium

  &.is-large
    +button-large

  // Colors
  &.is-success,
  &.is-primary
    background-color: $primary
    border-color: transparent
    color: $white
    transition: opacity 0.3s

    &:hover,
    &.is-hovered
      background-color: darken($primary, 5%)

  &.is-failure
    background-color: $red
    border-color: transparent
    color: $white

    &:hover,
    &.is-hovered
      background-color: darken($red, 5%)

  &.is-secondary
    background-color: $secondary
    border-color: transparent
    color: $white

    &:hover,
    &.is-hovered
      background-color: darken($secondary, 5%)

  &.is-link
    background-color: transparent
    border-color: transparent
    color: $text
    text-decoration: underline

    &:hover,
    &.is-hovered,
    &:focus,
    &.is-focused,
    &:active,
    &.is-active
      background-color: $background
      color: $text-strong

  // Modifiers
  &[disabled],
  &.is-disabled
    cursor: not-allowed
    opacity: .5

  &.is-fullwidth
    display: flex
    width: 100%
