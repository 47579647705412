// Colors
$lv-green: #26D07c !default
$lv-blue: #13377d !default

$black: #0a0a0a !default

$grey-darker: #2f2f2f !default
$grey-dark: #5e5e5e !default
$grey: #8e8e8e !default
$grey-light: #bdbdbd !default
$grey-lighter: #ededed !default

$white: #ffffff !default

$orange: #ff470f !default
$yellow: #ffdd57 !default
$green: #23d160 !default
$blue: #3273dc !default
$purple: #b86bff !default
$red: #b81d1d !default

// Primary colors
$primary: $lv-green !default
$primary-invert: findColorInvert($primary) !default
$secondary: $lv-blue !default
$secondary-invert: findColorInvert($secondary) !default

$info: $blue !default
$success: $green !default
$warning: $yellow !default
$danger: $red !default

$light: $white !default
$dark: $grey-darker !default

// General colors
$background: $white !default

$border: $grey-lighter !default
$border-hover: $grey-light !default

// Text colors
$text: $grey-dark !default
$text-light: $grey !default
$text-strong: $grey-darker !default

// Link colors
$link: $primary !default
$link-visited: $purple !default

$link-hover: $grey-darker !default
$link-hover-border: $grey-light !default

$link-focus: $grey-darker !default
$link-focus-border: $primary !default

$link-active: $grey-darker !default
$link-active-border: $grey-dark !default

$transparent: rgba(0, 0, 0, .45) !default

// Typography
$family-sans-serif: 'Nunito', -apple-system, BlinkMacSystemFont, 'Helvetica Neue', 'Helvetica', 'Arial', sans-serif !default

$size-1: 3.5rem !default
$size-2: 2.75rem !default
$size-3: 2rem !default
$size-4: 1.5rem !default
$size-5: 1.25rem !default
$size-6: 16px !default
$size-7: .75rem !default
$size-8: .5rem !default

$weight-light: 300 !default
$weight-normal: 400 !default
$weight-semibold: 500 !default
$weight-bold: 700 !default

$family-primary: $family-sans-serif !default

$size-small: $size-7 !default
$size-normal: 1rem !default
$size-medium: $size-5 !default
$size-large: $size-4 !default

// Miscellaneous
$easing: ease-out !default
$radius-small: 2px !default
$radius: 5px !default
$radius-large: 5px !default
$speed: 350ms !default

// Responsiveness
$tablet: 769px !default
// 960px container + 40px
$desktop: 1000px !default
// 1152px container + 40
$widescreen: 1192px !default

// Mixins
=arrow($color)
  border: 1px solid $color
  border-right: 0
  border-top: 0
  content: ' '
  display: block
  height: .5em
  pointer-events: none
  position: absolute
  transition: border-color $speed $easing
  transform: rotate(-45deg)
  width: .5em

=block
  &:not(:last-child)
    margin-bottom: 1.5rem

=fa($size, $dimensions)
  display: inline-block
  font-size: $size
  height: $dimensions
  line-height: $dimensions
  text-align: center
  vertical-align: top
  width: $dimensions

=placeholder
  $placeholders: ':-moz' ':-webkit-input' '-moz' '-ms-input'
  @each $placeholder in $placeholders
    &:#{$placeholder}-placeholder
      @content

// Media Queries
=from($device)
  @media screen and (min-width: $device)
    @content

=until($device)
  @media screen and (max-width: $device - 1px)
    @content

=mobile
  @media screen and (max-width: $tablet - 1px)
    @content

=tablet
  @media screen and (min-width: $tablet)
    @content

=tablet-only
  @media screen and (min-width: $tablet) and (max-width: $desktop - 1px)
    @content

=touch
  @media screen and (max-width: $desktop - 1px)
    @content

=desktop
  @media screen and (min-width: $desktop)
    @content

=desktop-only
  @media screen and (min-width: $desktop) and (max-width: $widescreen - 1px)
    @content

=widescreen
  @media screen and (min-width: $widescreen)
    @content

// Animations
@keyframes fadeIn
  from
    opacity: 0

  to
    opacity: 1

@keyframes fadeOut
  from
    opacity: 1

  to
    opacity: 0

@keyframes flipH
  0%
    transform: scaleX(-1)

  100%
    transform: scaleX(0)

@keyframes pulse
  0%
    box-shadow: inset 0 0 0 300px transparent

  70%
    box-shadow: inset 0 0 0 300px rgba(255, 255, 255, 0.25)

  100%
    box-shadow: inset 0 0 0 300px transparent
