@import '../../variables';

.icon {
  @include fa(21px, 1.5rem);
  transition: color $speed $easing;
  will-change: color;

  // Sizes
  &.is-small {
    @include fa(14px, 1rem);
  }

  &.is-medium {
    @include fa(28px, 2rem);
  }

  &.is-large {
    @include fa(42px, 3rem);
  }
}
