@import url('//fonts.googleapis.com/css?family=Nunito:400,600,700');

@import 'scss/utilities/all';
@import 'scss/base/all';
@import 'scss/elements/all';

body {
  display: flex;
  height: 100%;
}

lv-root {
  display: flex;
  flex: 1;
  width: 100%;
}

.lv-loader {
  margin: auto;

  span {
    background-color: $lv-green;
    display: inline-block;
    height: 20px;
    width: 5px;

    &:nth-child(1) {
      animation: grow 1s ease-in-out infinite;
    }

    &:nth-child(2) {
      animation: grow 1s ease-in-out .15s infinite;
    }

    &:nth-child(3) {
      animation: grow 1s ease-in-out .3s infinite;
    }

    &:nth-child(4) {
      animation: grow 1s ease-in-out .45s infinite;
    }
  }
}

@keyframes grow {
  0%, 100% {
    transform: scaleY(1);
  }

  50% {
    transform: scaleY(1.8);
  }
}
