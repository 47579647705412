$control-radius: 0 !default

=control
  align-items: center
  appearance: none
  border: none
  border-radius: $control-radius
  box-shadow: none
  display: inline-flex
  font-size: $size-normal
  height: 2.285em
  justify-content: flex-start
  line-height: 1.5
  padding-left: 0.75em
  padding-right: 0.75em
  position: relative
  vertical-align: top

  // States
  &:focus,
  &.is-focused,
  &:active,
  &.is-active
    outline: none

  &[disabled],
  &.is-disabled
    pointer-events: none
