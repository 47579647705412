$table: $grey-darker !default
$table-background: $white !default
$table-border: $grey-lighter !default

$table-head: $grey !default

$table-row-hover-background: rgba(0, 0, 0, .05) !default

$table-row-active-background: $primary !default
$table-row-active: $primary-invert !default

$table-row-even-background: $table-row-hover-background !default
$table-row-even-hover-background: rgba(0, 0, 0, .1) !default

.table
  background-color: $table-background
  color: $table
  margin-bottom: 1.5rem
  width: 100%

  td,
  th
    border: 1px solid $table-border
    border-width: 0 0 1px
    padding: .5em .75em
    vertical-align: top

    // Modifiers
    &.is-narrow
      white-space: nowrap
      width: 1%

  th
    color: $text-strong
    text-align: left

  tr
    &:hover
      background-color: $table-row-hover-background

    &.is-selected
      background-color: $table-row-active-background
      color: $table-row-active

      a,
      strong
        color: currentColor

      td,
      th
        border-color: $table-row-active
        color: currentColor

  thead
    td,
    th
      border-width: 0 0 2px
      color: $table-head

  tfoot
    td,
    th
      border-width: 2px 0 0
      color: $table-head

  tbody
    tr
      &:last-child
        td,
        th
          border-bottom-width: 0

  // Modifiers
  &.is-bordered
    td,
    th
      border-width: 1px

    tr
      &:last-child
        td,
        th
          border-bottom-width: 1px

  &.is-narrow
    td,
    th
      padding: .25em .5em

  &.is-striped
    tbody
      tr:not(.is-selected)
        &:nth-child(even)
          background-color: $table-row-even-background

          &:hover
            background-color: $table-row-even-hover-background
