$body-background: $white !default;
$body-size: $size-6 !default;

html, body {
  height: 100%;
}

html {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-size: $body-size;
  min-width: 300px;
  overflow: hidden;
  text-rendering: optimizeLegibility;
}

article,
aside,
figure,
footer,
header,
hgroup,
section {
  display: block;
}

body,
button,
input,
select,
textarea {
  font-family: $family-primary;
}

body {
  color: $text;
  font-size: 1rem;
  font-weight: $weight-normal;
  line-height: 1.5;
}

// Inline
a {
  color: $link;
  cursor: pointer;
  text-decoration: none;
  transition: none $speed $easing;

  &:hover {
    color: $link-hover;
  }
}

hr {
  background-color: $border;
  border: none;
  display: block;
  height: 1px;
  margin: 1.5rem 0;
}

input {
  &[type="checkbox"],
  &[type="radio"] {
    vertical-align: baseline;
  }
}

small {
  font-size: 0.8em;
}

span {
  font-style: inherit;
  font-weight: inherit;
}

strong {
  color: $text-strong;
  font-weight: $weight-bold;
}

// Block
table {
  width: 100%;

  td,
  th {
    text-align: left;
    vertical-align: top;
  }

  th {
    color: $text-strong;
  }
}

video {
  display: block;
}

// Content
h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 400;
  line-height: 1.125;
}

h1 {
  font-size: 2em;
  margin-bottom: 0.5em;

  &:not(:first-child) {
    margin-top: 1em;
  }
}

h2 {
  font-size: 1.75em;
  margin-bottom: .5714em;

  &:not(:first-child) {
    margin-top: 1.1428em;
  }
}

h3 {
  font-size: 1.5em;
  margin-bottom: .6666em;

  &:not(:first-child) {
    margin-top: 1.3333em;
  }
}

h4 {
  font-size: 1.25em;
  margin-bottom: .8em;
}

h5 {
  font-size: 1.125em;
  margin-bottom: .8888em;
}

h6 {
  font-size: 1em;
  margin-bottom: 1em;
}
