$progress-bar-background-color: $border !default
$progress-value-background-color: $text !default

.progress
  +block
  border: none
  border-radius: $radius
  display: block
  height: $size-normal
  overflow: hidden
  padding: 0
  width: 100%

  &::-webkit-progress-bar
    background-color: $progress-bar-background-color

  &::-webkit-progress-value
    background-color: $progress-value-background-color

  &::-moz-progress-bar
    background-color: $progress-value-background-color

  &::-ms-fill
    background-color: $progress-value-background-color
    border: none

  &.is-small
    height: $size-small

  &.is-medium
    height: $size-medium

  &.is-large
    height: $size-large